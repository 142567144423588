import { StackScreenProps } from "@react-navigation/stack";
import React from "react";
import { Platform, View } from "react-native";
import { Card } from "react-native-elements";
import { useIsConnected } from "react-native-offline";
import * as Yup from "yup";
import Form from "../../components/Forms/Form";
import FormButton from "../../components/Forms/FormButton";
import { FormInput } from "../../components/Forms/FormField";
import { Text } from "../../components/Themed";
import { useStores } from "../../models/root-store/root-store-context";
import styles from "../../styles";

const validationSchema = Yup.object().shape({
  username: Yup.string().required().label("Username"),
  password: Yup.string().required().label("Password"),
});

export default function AuthScreen({
  navigation,
  route,
}: StackScreenProps<{}>) {
  const { authStore } = useStores();
  const isConnected = Platform.OS == "web" ? true : useIsConnected();

  const [online, setOnline] = React.useState<boolean | null>();
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState<boolean>();

  React.useEffect(() => {
    setOnline(isConnected);
  }, [isConnected]);

  const login = async ({ username, password }: any) => {
    setLoading(true);
    const resp = await authStore.signIn(username, password);
    if (!resp) {
      setSuccess(resp);
      setLoading(false);
    }
  };

  return (
    <View style={styles.centeredContainer}>
      <Card containerStyle={styles.loginContainer}>
        <Text style={styles.centeredText} h3>
          Login
        </Text>
        {success !== undefined && !success && (
          <Text style={[styles.small, styles.errorText]}>
            Invalid username or password. Please try again.
          </Text>
        )}
        <Form
          initialValues={{
            username: "",
            password: "",
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => login(values)}
        >
          <FormInput leftIcon="user" placeholder="Username" name="username" />
          <FormInput
            leftIcon="lock"
            placeholder="Password"
            secureTextEntry={true}
            name="password"
          />
          <FormButton
            raised
            activeOpacity={0.8}
            title="LOGIN"
            containerStyle={{ flex: 1 }}
            loading={loading}
            // disabled={loading || !online}
          />
        </Form>
        {!online && (
          <Text style={[styles.centeredText, styles.errorText]}>
            Not connected.
          </Text>
        )}
      </Card>
    </View>
  );
}
