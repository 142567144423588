import { Instance, SnapshotOut, types } from "mobx-state-tree";
import UserAuthApi from "../services/api/userAuth";
import constants from "../utils/constants";
import * as storage from "../utils/storage";
import withEnvironment from "./extensions/with-environment";
import { UserModel, UserSnapshot } from "./User";

export const AuthStoreModel = types
  .model("AuthStore")
  .props({
    isAuthenticated: types.optional(types.boolean, false),
    codeValidated: types.optional(types.boolean, false),
    lastTokenRefresh: types.maybeNull(types.Date),
    user: types.maybeNull(UserModel),
    companyUid: types.maybeNull(types.string),
    companyName: types.maybeNull(types.string),
    role: types.maybeNull(types.string),
    roleUid: types.maybeNull(types.string),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    setIsAuthenticated(val: boolean) {
      self.isAuthenticated = val;
    },
    setCodeValidated(val: boolean) {
      self.codeValidated = val;
    },
    setCompanyUid(val: string) {
      self.companyUid = val;
    },
    setCompanyName(val: string) {
      self.companyName = val;
    },
    setRole(val: string) {
      self.role = val;
    },
    setRoleUid(val: string) {
      self.roleUid = val;
    },
    setLastTokenRefresh() {
      self.lastTokenRefresh = new Date();
    },
    setCurrentUser(user: UserSnapshot) {
      self.user = UserModel.create(user);
    },
    resetAll() {
      self.user = null;
      self.lastTokenRefresh = null;
      self.companyUid = null;
      self.companyName = null;
      self.role = null;
    },
    updateStorage: async (data: UserSnapshot) => {
      if (data.companyName) {
        await storage.saveString(constants.COMPANY_NAME, data.companyName);
      }
      if (data.role) {
        await storage.saveString(constants.ROLE_NAME, data.role);
      }
      if (data.roleUid) {
        await storage.saveString(constants.ROLE_UID, data.roleUid);
      }
      if (data.companyUid) {
        await storage.saveString(constants.COMPANY_UID, data.companyUid);
      }
    },
    resetStorage: async () => {
      await Promise.all([
        storage.remove(constants.ROOT_STATE_STORAGE_KEY),
        storage.remove(constants.TOKEN),
        storage.remove(constants.REFRESH_TOKEN),
        storage.remove(constants.COMPANY_UID),
        storage.remove(constants.ROLE_NAME),
        storage.remove(constants.COMPANY_NAME),
      ]);
    },
  }))
  .actions((self) => ({
    getCurrentUser: async () => {
      const auth = new UserAuthApi(self.environment.api);
      const resp = await auth.CurrentUser();

      if (resp.data) {
        self.updateStorage(resp.data);
        self.setCurrentUser(resp.data);
        self.setCompanyName(resp.data.companyName);
        self.setCompanyUid(resp.data.companyUid);
        self.setRole(resp.data.role);
        self.setRoleUid(resp.data.roleUid);
      }
      return self.user;
    },
  }))
  .actions((self) => ({
    async checkPhone(phoneNumber: string) {
      const auth = new UserAuthApi(self.environment.api);
      return auth.CheckPhone(phoneNumber);
    },
    refreshToken: async () => {
      if (self.lastTokenRefresh) {
        const now = new Date().getTime();
        const duration = now - self.lastTokenRefresh.getTime();
        if (duration < 5000) {
          return;
        }
      }
      self.setLastTokenRefresh();
      const auth = new UserAuthApi(self.environment.api);
      await auth.RefreshToken();
    },
    validateCode: async (req: {}) => {
      const auth = new UserAuthApi(self.environment.api);
      const resp = await auth.ValidateQrCode(req);
      const { ok } = resp;
      self.getCurrentUser();
      self.setCodeValidated(ok);
      return resp;
    },
    requestCode: async () => {
      const auth = new UserAuthApi(self.environment.api);
      return await auth.RequestQrCode();
    },
  }))
  .actions((self) => ({
    async signOut() {
      const auth = new UserAuthApi(self.environment.api);
      await auth.RevokeToken(), self.setIsAuthenticated(false);
      self.setCodeValidated(false);
      self.resetAll();
      self.resetStorage();
    },
    async signIn(username: string, password: string) {
      const auth = new UserAuthApi(self.environment.api);
      const success = await auth.SignIn(username, password);
      self.setIsAuthenticated(success);
      if (success && username === constants.DEMO_USERNAME) {
        self.setCodeValidated(true);
      }
      return success;
    },
  }));

type AuthStoreType = Instance<typeof AuthStoreModel>;
export interface AuthStore extends AuthStoreType {}
type AuthStoreSnapshotType = SnapshotOut<typeof AuthStoreModel>;
export interface AuthStoreSnapshot extends AuthStoreSnapshotType {}
export const createAuthStoreDefaultModel = () =>
  types.optional(AuthStoreModel, {});
