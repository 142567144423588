import React from "react";
import { Text } from "../Themed";
import styles from "../../styles";

export default function FormErrorMessage({ error, visible }: any) {
  if (!error || !visible) {
    return null;
  }

  return <Text style={styles.errorText}>{error}</Text>;
}
