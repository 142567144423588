import { Dimensions } from "react-native";

const HOUR = 1 * 60 * 60 * 1000; // ms

const constants = {
  SCREEN_HEIGHT: Dimensions.get("window").height,
  SCREEN_WIDTH: Dimensions.get("window").width,
  COMPANY_UID: "company_uid",
  ROLE_NAME: "role_name",
  ROLE_UID: "role_uid",
  COMPANY_NAME: "company_name",
  TOKEN: "token",
  REFRESH_TOKEN: "refreshToken",
  BASE_PADDING: 10,
  HALF_HOUR: HOUR / 2,
  REFRESH: "refresh",
  REVOKE: "revoke-token",
  DEFAULT_LIMIT: 10,
  ROOT_STATE_STORAGE_KEY: "root",
  USER_TYPE: "user",
  ADMIN_TYPE: "admin",
  ACCOUNT_TYPE: "account",
  ACCOUNT_SETTINGS: "account settings",
  ADD: "add",
  REMOVE: "remove",
  APPROVE: "approve",
  REJECT: "reject",
  CONNECTION_ERROR: "cannot-connect",
  TIMEOUT_ERROR: "timeout",
  SERVER_ERROR: "server",
  UNKNOWN_ERROR: "unknown",
  CLIENT_ERROR_401: "unauthorized",
  CLIENT_ERROR_403: "forbidden",
  CLIENT_ERROR_404: "not-found",
  CLIENT_ERROR: "rejected",
  PERCENT: "percent",
  AMOUNT: "amount",
  DEMO_USERNAME: "demo",
};

export default constants;
