import { Instance, SnapshotOut, types } from "mobx-state-tree";
import PingApi from "../services/api/ping-api";
import withEnvironment from "./extensions/with-environment";

export const PingModel = types
  .model("Ping")
  .props({
    ok: types.optional(types.boolean, false),
    problem: types.optional(types.string, ""),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    save: (ok: boolean) => {
      self.ok = ok; /* eslint-disable-line no-param-reassign */
    },
    saveMeta: (problem: string) => {
      self.problem = problem; /* eslint-disable-line no-param-reassign */
    },
  }))
  .actions((self) => ({
    checkApi: async () => {
      const ping = new PingApi(self.environment.api);
      const result = await ping.ping();
      self.save(result.ok);
      const kind = result.meta.problem?.kind;

      self.saveMeta(kind || "");
    },
  }));

type PingType = Instance<typeof PingModel>;
export interface Ping extends PingType {}
type PingSnapshotType = SnapshotOut<typeof PingModel>;
export interface PingSnapshot extends PingSnapshotType {}
export const createPingDefaultModel = () => types.optional(PingModel, {});
