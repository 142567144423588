import React from "react";
import { View } from "react-native";
import { Card, Input, Overlay, Text } from "react-native-elements";
import { AppButton } from "../../components/Themed";
import { useSharedStores } from "../../models/shared-store/shared-store-context";
import styles from "../../styles";
import constants from "../../utils/constants";

export default function Discount(props: any) {
  const { cart } = useSharedStores();
  const { data, visible, onDismiss } = props;
  const { item, discountType } = data;
  const {
    quantity,
    price,
    productUid,
    discount,
    discountAmount,
    discountPercent,
  } = item;

  const [discountVal, setDiscount] = React.useState("");
  const [error, setError] = React.useState("");

  const computeDiscount = (val: string) => {
    const valid = /[+-]?([0-9]*[.])?[0-9]+/.test(val);
    setError("");
    setDiscount(val);
    if (!valid) {
      setError("Please enter a valid number.");
      return;
    }
    cart.addItemDiscount(productUid, val, discountType);
  };

  React.useEffect(() => {
    if (discountType === constants.PERCENT) {
      setDiscount(`${discountPercent}`);
    } else {
      setDiscount(`${discount}`);
    }
  }, [discountType]);

  return (
    <Overlay
      style={styles.flexEvenlyContainer}
      isVisible={visible}
      onDismiss={onDismiss}
    >
      <Card containerStyle={[styles.formContainer, styles.noMargin]}>
        <Card.Title>Set Discount</Card.Title>
        <Card.Divider />
        <View>
          <View style={styles.row}>
            <Text style={styles.f1}>Price</Text>
            <Text style={styles.f1}>{price}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.f1}>
              Discount ({discountType === constants.PERCENT ? "%" : "sh"})
            </Text>
            <Input
              keyboardType="numeric"
              value={discountVal}
              inputStyle={styles.discountInput}
              onChangeText={(val) => computeDiscount(val)}
              errorStyle={{ color: "red", fontSize: 10 }}
              errorMessage={error}
              containerStyle={styles.f1}
            />
          </View>
          <View style={styles.row}>
            <Text style={styles.f1}>Quantity</Text>
            <Text style={styles.f1}>{quantity}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.f1}>Total Discount</Text>
            <Text style={styles.f1}>{discountAmount}</Text>
          </View>
          <View style={styles.row}>
            <Text style={[styles.total, styles.f1]}>After Discount</Text>
            <Text style={[styles.total, styles.f1]}>
              {price * quantity - discountAmount}
            </Text>
          </View>
        </View>
        <AppButton
          buttonStyle={styles.appButton}
          onPress={onDismiss}
          transparent={true}
          title="Ok"
        />
      </Card>
    </Overlay>
  );
}
