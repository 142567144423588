import { ApiResponse } from "apisauce";
import Api from "./api";
import { AppResponse, formatResponseMeta } from "./helpers";

const API_PAGE_SIZE = 50;

export default class PingApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async ping(): Promise<AppResponse> {
    const response: ApiResponse<any> = await this.api.apisauce.get(`ping`);
    return {
      ok: response.ok,
      data: response.data?.results || response.data,
      status: response.status,
      meta: formatResponseMeta(response),
    };
  }
}
