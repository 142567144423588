import { Instance, SnapshotOut, types } from "mobx-state-tree";
import withEnvironment from "./extensions/with-environment";

export const UserRoleModel = types
  .model("UserRole")
  .props({
    uid: types.maybeNull(types.string),
    isActive: types.maybeNull(types.boolean),
    addedBy: types.maybeNull(types.string),
    updatedAt: types.maybeNull(types.string),
    companyName: types.maybeNull(types.string),
    company: types.maybeNull(types.string),
    role: types.maybeNull(types.string),
  })
  .extend(withEnvironment)
  .actions((self) => ({}));

type UserRoleType = Instance<typeof UserRoleModel>;
export interface UserRole extends UserRoleType {}
type UserRoleSnapshotType = SnapshotOut<typeof UserRoleModel>;
export interface UserRoleSnapshot extends UserRoleSnapshotType {}
export const createUserRoleDefaultModel = () =>
  types.optional(UserRoleModel, {});
