import { Instance, SnapshotOut, types } from "mobx-state-tree";
import constants from "../utils/constants";

export const MetaModel = types
  .model("Meta")
  .props({
    count: types.maybeNull(types.integer),
    next: types.maybeNull(types.integer),
    previous: types.maybeNull(types.integer),
    limit: types.optional(types.integer, constants.DEFAULT_LIMIT),
    page: types.optional(types.integer, 1),
    maxPage: types.optional(types.integer, 1),
  })
  .actions((self) => ({
    saveMeta: (data: any) => {
      self.count = data.count;
      if (self.count) {
        self.maxPage = Math.floor(self.count / self.limit);
      }
      if (data.hasNext) {
        self.next = self.page + 1;
      } else {
        self.next = null;
      }
      if (data.hasPrevious) {
        self.previous = self.page - 1;
      } else {
        self.previous = null;
      }
    },
    savePage: (page: number) => {
      self.page = page;
    },
  }));

type MetaType = Instance<typeof MetaModel>;
export interface Meta extends MetaType {}
type MetaSnapshotType = SnapshotOut<typeof MetaModel>;
export interface MetaSnapshot extends MetaSnapshotType {}
export const createMetaDefaultModel = () => types.optional(MetaModel, {});
