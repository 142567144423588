/* eslint-disable no-nested-ternary */
/**
 * If you are not familiar with React Navigation, check out the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import { createDrawerNavigator } from "@react-navigation/drawer";
import { NavigationContainer } from "@react-navigation/native";
import AppLoading from "expo-app-loading";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";
import { AppState, AppStateStatus, ColorSchemeName, View } from "react-native";
import createEnvironment from "../models/extensions/utils";
import { useStores } from "../models/root-store/root-store-context";
import {
  SharedStore,
  SharedStoreModel,
} from "../models/shared-store/shared-store";
import { SharedStoreProvider } from "../models/shared-store/shared-store-context";
import styles from "../styles";
import { RootStackParamList } from "../types";
import AuthNavigator from "./AuthNavigator";
import DrawerNavigator from "./DrawerNavigator";
import NavigationService from "./NavigationService";

export default function Navigation({
  colorScheme,
}: {
  colorScheme: ColorSchemeName;
}) {
  return (
    <NavigationContainer
      ref={(navigatorRef) => {
        NavigationService.setTopLevelNavigator(navigatorRef);
      }}
    >
      <RootNavigator />
    </NavigationContainer>
  );
}

// A root stack navigator is often used for displaying modals on top of all other content
// Read more here: https://reactnavigation.org/docs/modal
const Stack = createDrawerNavigator<RootStackParamList>();

const RootNavigator = observer(() => {
  const [checked, setChecked] = useState(false);
  const [isReady, setIsReady] = useState(false);

  const { ping, authStore } = useStores();
  const { codeValidated } = authStore;

  const [store, setStore] = useState<SharedStore | undefined>(undefined);
  const appState = useRef(AppState.currentState);
  const [appStateVisible, setAppStateVisible] = useState(appState.current);

  useEffect(() => {
    AppState.addEventListener("change", _handleAppStateChange);

    return () => {
      AppState.removeEventListener("change", _handleAppStateChange);
    };
  }, []);

  const _handleAppStateChange = (nextAppState: AppStateStatus) => {
    appState.current = nextAppState;
    setAppStateVisible(appState.current);
  };

  useEffect(() => {
    async function Ping() {
      await ping.checkApi();
      if (ping.problem == "forbidden") {
        authStore.signOut();
      }
      await authStore.getCurrentUser();
      setChecked(true);
    }
    Ping();
  }, [ping, appStateVisible]);

  useEffect(() => {
    authStore.refreshToken();
  }, [authStore]);

  if (!checked) {
    return (
      <View style={styles.centeredContainer}>
        <View style={styles.formContainer}></View>
      </View>
    );
  }

  async function prepareEnv() {
    const env = await createEnvironment();
    const connectionStore = SharedStoreModel.create({}, env);
    setStore(connectionStore);
  }

  if (!isReady || store === undefined) {
    return (
      <AppLoading
        startAsync={prepareEnv}
        onFinish={() => setIsReady(true)}
        onError={console.warn}
      />
    );
  }

  return (
    <SharedStoreProvider value={store}>
      <Stack.Navigator
        screenOptions={{
          headerShown: false,
          drawerStyle: styles.mainDrawer,
          sceneContainerStyle: styles.main,
        }}
      >
        {codeValidated ? (
          <Stack.Screen name="Root" component={DrawerNavigator} />
        ) : (
          <Stack.Screen name="Auth" component={AuthNavigator} />
        )}
      </Stack.Navigator>
    </SharedStoreProvider>
  );
});
