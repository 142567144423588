import {
  createStackNavigator,
  StackNavigationOptions,
  StackScreenProps,
} from "@react-navigation/stack";
import * as React from "react";
import { Platform } from "react-native";
import { useIsConnected } from "react-native-offline";
import { HomeScreen } from "../screens";
import DashboardScreen from "../screens/Dashboard";
import { HomeParamList } from "../types";

const HomeStack = createStackNavigator<HomeParamList>();

export default function HomeNavigator({
  route,
  navigation,
  screenOptions,
}: StackScreenProps<HomeParamList> & {
  screenOptions?: StackNavigationOptions;
}) {
  const isConnected = Platform.OS == "web" ? true : useIsConnected();

  const [online, setOnline] = React.useState<boolean | null>();

  React.useEffect(() => {
    setOnline(isConnected);
  }, [isConnected]);

  return (
    <HomeStack.Navigator
      initialRouteName="HomeScreen"
      screenOptions={{ headerShown: false, ...screenOptions }}
    >
      <HomeStack.Screen
        options={{ title: "POS" }}
        name="HomeScreen"
        component={HomeScreen}
      />
      <HomeStack.Screen
        options={{ title: "POS" }}
        name="DashScreen"
        component={DashboardScreen}
      />
    </HomeStack.Navigator>
  );
}
