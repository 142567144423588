import {
  destroy,
  getSnapshot,
  Instance,
  SnapshotOut,
  types,
} from "mobx-state-tree";
import "react-native-get-random-values";
import { v4 as uuidv4 } from "uuid";
import { ProductSnapshot } from ".";
import ProductApi from "../services/api/product";
import constants from "../utils/constants";
import * as storage from "../utils/storage";
import { CartItemModel, CartItemSnapshot } from "./CartItem";
import withEnvironment from "./extensions/with-environment";

export const CartModel = types
  .model("Cart")
  .props({
    uid: types.optional(types.string, ""),
    status: types.optional(types.string, "open"),
    items: types.map(CartItemModel),
    count: types.optional(types.number, 0),
    subtotal: types.optional(types.number, 0),
    saleDiscount: types.optional(types.number, 0),
    itemsDiscount: types.optional(types.number, 0),
    total: types.optional(types.number, 0),
    tax: types.optional(types.number, 0),
  })
  .extend(withEnvironment)
  .views((self) => ({}))
  .actions((self) => ({
    setUid: (val: string) => {
      self.uid = val;
    },
    setStatus: (val: string) => {
      self.status = val;
    },
    loadItem: (item: CartItemSnapshot) => {
      self.items.set(item.productUid, CartItemModel.create(item));
    },
    setCount: (val: number) => {
      self.count = val;
    },
    setSubTotal: (val: number) => {
      self.subtotal = val;
    },
    setItemsDiscount: (val: number) => {
      self.itemsDiscount = val;
    },
    setSaleDiscount: (val: number) => {
      self.saleDiscount = val;
    },
    setTotal: (val: number) => {
      self.total = val;
    },
  }))
  .actions((self) => ({
    recordTransaction: async (data: any) => {
      const company_uid = await storage.loadString(constants.COMPANY_UID);
      const transaction = {
        sale: data.uid,
        payment_method: data.method,
        amount: data.custAmount,
        status: "success",
        company: company_uid,
      };
      const endpoint = new ProductApi(self.environment.api);
      const { ok } = await endpoint.recordTransaction(transaction);

      if (ok) {
        self.reset();
      }
    },
    compute: async (record: boolean = true) => {
      let count = 0;
      let subtotal = 0;
      let discount = 0;
      let total = 0;

      Object.values(self.items.toJSON()).map((item) => {
        const price = item.price * item.quantity;
        count += 1;
        subtotal += price;
        discount += item.discountAmount;
        total += price - item.discountAmount;
      });

      self.setCount(count);
      self.setSubTotal(subtotal);
      self.setItemsDiscount(discount);
      self.setTotal(total - self.saleDiscount);

      if (record && self.uid) {
        const endpoint = new ProductApi(self.environment.api);
        await endpoint.recordSale(getSnapshot(self));
      }
    },
  }))
  .actions((self) => ({
    reset: () => {
      self.items.forEach((item: CartItemSnapshot) => {
        destroy(item);
      });
      self.setUid("");
      self.setStatus("open");
      self.compute(false);
    },
  }))
  .actions((self) => ({
    load: async () => {
      const endpoint = new ProductApi(self.environment.api);
      const resp = await endpoint.getOpenCartItems();
      const { data } = resp;
      const { items, status, sale } = data;
      if (!sale) {
        self.reset();
        return;
      }
      self.setUid(sale);
      self.setStatus(status);
      items.forEach((item: CartItemSnapshot) => {
        self.loadItem(item);
      });
      self.compute(false);
    },
    computeDiscount: (item: CartItemSnapshot) => {
      let amount = item.discount * item.quantity;

      if (item.discountType === constants.PERCENT) {
        amount = ((+item.discountPercent * item.price) / 100) * item.quantity;
      }
      item.discountAmount = amount;
    },
  }))
  .actions((self) => ({
    setProcessing: () => {
      self.setStatus("processing");
      self.compute();
    },
    addItem: (uid: string) => {
      const existing = self.items.get(uid);
      if (existing) {
        existing.quantity += 1;
        self.computeDiscount(existing);
        existing.amount =
          existing.quantity * existing.price - existing.discountAmount;
        self.items.put(existing);
      }
      self.compute();
    },
  }))
  .actions((self) => ({
    addItemDiscount: (uid: string, val: string, type: "amount" | "percent") => {
      const item = self.items.get(uid);
      if (item) {
        item.discountType = type;
        if (type === constants.PERCENT) {
          item.discountPercent = val;
          item.discount = (+val * item.price) / 100;
        } else {
          item.discount = +val;
          item.discountPercent = `${(
            (item.discount / item.price) *
            100
          ).toFixed(2)}`;
        }
        self.computeDiscount(item);
        self.items.put(item);
      }
      self.compute(false);
    },
  }))
  .actions((self) => ({
    addCartDiscount: (val: number) => {
      self.setSaleDiscount(val);
      self.compute();
    },
  }))
  .actions((self) => ({
    removeItem: (uid: string) => {
      const existing = self.items.get(uid);
      if (!existing) {
        return;
      }
      const newQty = existing.quantity - 1;
      if (newQty < 1) {
        destroy(existing);
        self.compute();
        return;
      }
      existing.quantity = newQty;
      self.computeDiscount(existing);
      self.items.put(existing);
      self.compute();
    },
  }))
  .actions((self) => ({
    addToCart: (item: ProductSnapshot) => {
      if (!(item.name && item.price && item.uid && item.priceUid)) {
        return;
      }
      const existing = self.items.get(item.uid);

      let quantity = 1;
      if (existing) {
        self.addItem(item.uid);
        return;
      }

      const cartItem: CartItemSnapshot = {
        uid: uuidv4(),
        productUid: item.uid,
        name: item.name,
        quantity: quantity,
        price: item.price,
        priceUid: item.priceUid,
        discount: 0,
        discountAmount: 0,
        discountPercent: "0",
        amount: item.price,
        discountType: "amount",
      };
      self.items.set(item.uid, CartItemModel.create(cartItem));
      self.compute();
    },
  }));

type CartType = Instance<typeof CartModel>;
export interface Cart extends CartType {}
type CartSnapshotType = SnapshotOut<typeof CartModel>;
export interface CartSnapshot extends CartSnapshotType {}
export const createCartDefaultModel = () => types.optional(CartModel, {});
