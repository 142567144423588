/**
 * Learn more about createBottomTabNavigator:
 * https://reactnavigation.org/docs/bottom-tab-navigator
 */

import {
  createDrawerNavigator,
  DrawerNavigationOptions,
} from "@react-navigation/drawer";
import { StackScreenProps } from "@react-navigation/stack";
import * as React from "react";
import { Platform } from "react-native";
import { useIsConnected } from "react-native-offline";
import { HeaderLeft, HeaderRight } from "../components";
import * as Colors from "../constants/Colors";
import useColorScheme from "../hooks/useColorScheme";
import { useStores } from "../models/root-store/root-store-context";
import { useSharedStores } from "../models/shared-store/shared-store-context";
import { DrawerParamList } from "../types";
import HomeNavigator from "./HomeNavigator";

const tintColorLight = "#2f95dc";
const tintColorDark = "#fff";

const COLORS = {
  light: {
    text: "#000",
    background: "#fff",
    tint: tintColorLight,
    tabIconDefault: "#ccc",
    tabIconSelected: tintColorLight,
  },
  dark: {
    text: "#fff",
    background: "#000",
    tint: tintColorDark,
    tabIconDefault: "#ccc",
    tabIconSelected: tintColorDark,
  },
};

const Drawer = createDrawerNavigator();

export default function DrawerNavigator({
  route,
  navigation,
  screenOptions,
}: StackScreenProps<DrawerParamList> & {
  screenOptions?: DrawerNavigationOptions;
}) {
  const colorScheme = useColorScheme();
  const { authStore } = useStores();
  const { cart } = useSharedStores();

  const { roleUid } = authStore;

  React.useEffect(() => {
    authStore.refreshToken();
  }, [authStore]);

  React.useEffect(() => {
    async function Load() {
      await cart.load();
    }
    Load();
  }, [roleUid]);

  React.useEffect(() => {
    async function Ping() {
      await authStore.getCurrentUser();
    }
    Ping();
  }, [authStore]);

  const isConnected = Platform.OS == "web" ? true : useIsConnected();

  const [online, setOnline] = React.useState<boolean | null>();

  React.useEffect(() => {
    setOnline(isConnected);
  }, [isConnected]);

  return (
    <Drawer.Navigator
      initialRouteName="Home"
      screenOptions={{
        drawerActiveTintColor: COLORS[colorScheme].tint,
        ...screenOptions,
      }}
    >
      <Drawer.Screen
        name="Home"
        component={HomeNavigator}
        options={{
          headerStyle: {
            backgroundColor: online ? undefined : Colors.orange500,
          },
          title: online ? "" : "You are offline. Connection is required",
          headerRight: () => (
            <HeaderRight route={route} navigation={navigation} />
          ),
          headerLeft: () => (
            <HeaderLeft route={route} navigation={navigation} />
          ),
        }}
      />
    </Drawer.Navigator>
  );
}
