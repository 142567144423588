import React from "react";
import { Text, View } from "react-native";
import * as Yup from "yup";
import { useSharedStores } from "../../models/shared-store/shared-store-context";
import styles from "../../styles";
import Form from "../Forms/Form";
import FormButton from "../Forms/FormButton";
import { FormInput, FormRadio } from "../Forms/FormField";
import { Button } from "../Themed";

const validationSchema = Yup.object().shape({
  method: Yup.string().required().label("Payment Method"),
  custAmount: Yup.string().required().label("Amount"),
});

export default function Payment() {
  const { cart } = useSharedStores();

  const { total, uid } = cart;

  const [change, setChange] = React.useState<number>(0);
  const [amount, setAmount] = React.useState<number>(0);
  const [amountValid, setAmountValid] = React.useState(false);

  const amountInput = (val: string) => {
    const valInt = +val;
    setAmountValid(false);
    setAmount(valInt);
    setChange(valInt - total);
    if (valInt >= total) {
      setAmountValid(true);
    }
  };

  const recordPayment = (data: any) => {
    data.uid = uid;
    cart.recordTransaction(data);
  };

  const cancel = () => {
    cart.setStatus("open");
  };

  const methods: any = [
    { label: "Cash", value: "cash" },
    { label: "M-PESA", value: "mpesa" },
    { label: "Card", value: "card" },
  ];

  return (
    <View style={[styles.formContainer]}>
      <Text style={[styles.tenPadding, styles.centered, styles.heading]}>
        Record Transaction
      </Text>
      <Form
        initialValues={{
          method: "",
          custAmount: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => recordPayment(values)}
      >
        <View style={styles.inputContainer}>
          <FormRadio
            style={styles.f1}
            placeholder="Select One"
            name="method"
            data={methods}
            label="Payment Method"
          />
        </View>
        <View style={styles.inputContainer}>
          <FormInput
            label="Amount"
            small={true}
            placeholder=""
            name="custAmount"
            onChangeText={amountInput}
          />
          {change > 0 && (
            <View style={styles.centered}>
              <View style={styles.inputContainer}>
                <Text style={[styles.inputLabel]}>Change: </Text>
                <Text>{change}</Text>
              </View>
            </View>
          )}
        </View>

        <View style={[styles.inputContainer, styles.tenPadding]}>
          <Button
            raised
            type="outline"
            title="Cancel"
            onPress={cancel}
            containerStyle={{ flex: 1 }}
          />
          <FormButton
            raised
            activeOpacity={0.8}
            title="Submit"
            containerStyle={{ flex: 1 }}
            disabled={!amountValid}
          />
        </View>
      </Form>
    </View>
  );
}
