/**
 * Learn more about Light and Dark modes:
 * https://docs.expo.io/guides/color-schemes/
 */

import * as React from "react";
import { View } from "react-native";
import {
  Button as RNEButton,
  Icon,
  Input as RNETextInput,
  Text as RNEText,
} from "react-native-elements";
import * as Colors from "../constants/Colors";
import styles from "../styles";

export function Text(props: any) {
  return <RNEText style={styles.defaultText} {...props} />;
}

export function Button(props: any) {
  return (
    <View style={styles.buttonContainer}>
      <RNEButton
        style={styles.button}
        labelStyle={styles.buttonText}
        {...props}
      />
    </View>
  );
}

export function AppButton(props: any) {
  return <Button {...props} />;
}

export function TextInput({
  leftIcon,
  rightIcon,
  leftPress,
  rightPress,
  onPress,
  ...inputProps
}: any) {
  if (leftIcon) {
    inputProps.leftIcon = (
      <Icon
        style={styles.inputIcon}
        name={leftIcon}
        size={24}
        color="black"
        onPress={leftPress}
        type="font-awesome"
      />
    );
  }
  if (rightIcon) {
    inputProps.rightIcon = (
      <Icon
        style={styles.inputIcon}
        name={rightIcon}
        size={24}
        color="black"
        onPress={rightPress}
        type="font-awesome"
      />
    );
  }

  const { style, small, ...props } = inputProps;

  return (
    <RNETextInput
      style={{ ...styles.inputText, ...style }}
      containerStyle={styles.inputContainer}
      placeholderTextColor={Colors.grey600}
      inputContainerStyle={{ width: small === undefined ? "100%" : null }}
      {...props}
    />
  );
}
