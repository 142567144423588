import { StatusBar } from "expo-status-bar";
import React from "react";
import { ThemeProvider } from "react-native-elements";
import { NetworkProvider } from "react-native-offline";
import { SafeAreaProvider } from "react-native-safe-area-context";
import useCachedResources from "./src/hooks/useCachedResources";
import useColorScheme from "./src/hooks/useColorScheme";
import { RootStore } from "./src/models/root-store/root-store";
import { RootStoreProvider } from "./src/models/root-store/root-store-context";
import setupRootStore from "./src/models/root-store/setup-root-store";
import Navigation from "./src/navigation";
import {
  initialState,
  ThemeReducer,
  ThemeReducerContext,
} from "./src/utils/ThemeReducer";

export default function App() {
  const isLoadingComplete = useCachedResources();
  const colorScheme = useColorScheme();
  const [rootStore, setRootStore] = React.useState<RootStore | undefined>(
    undefined
  );
  const [ThemeState, dispatch] = React.useReducer(ThemeReducer, initialState);

  React.useEffect(() => {
    (async () => {
      setupRootStore().then(setRootStore);
    })();
  }, []);

  if (!isLoadingComplete || !rootStore) {
    return null;
  } else {
    return (
      <NetworkProvider>
        <ThemeReducerContext.Provider value={{ ThemeState, dispatch }}>
          <ThemeProvider
            useDark={ThemeState.themeMode === "dark" ? true : false}
          >
            <RootStoreProvider value={rootStore}>
              <SafeAreaProvider>
                <Navigation colorScheme={colorScheme} />
                <StatusBar />
              </SafeAreaProvider>
            </RootStoreProvider>
          </ThemeProvider>
        </ThemeReducerContext.Provider>
      </NetworkProvider>
    );
  }
}
