import React from "react";
import { TouchableOpacity, View } from "react-native";
import { Icon } from "react-native-elements";
import { useStores } from "../../models/root-store/root-store-context";
import styles from "../../styles";

export default function HeaderRight(props: any) {
  const { authStore } = useStores();

  const logout = async () => {
    await authStore.signOut();
  };

  return (
    <View style={[styles.flexEvenlyContainer, styles.tenPadding]}>
      <View style={styles.avatarContainer}>
        <TouchableOpacity onPress={() => props.navigationProps.toggleDrawer()}>
          <Icon
            onPress={logout}
            size={18}
            raised
            solid
            name="power"
            type="material-community"
          />
        </TouchableOpacity>
      </View>
    </View>
  );
}
