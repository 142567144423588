import { StackScreenProps } from "@react-navigation/stack";
import React from "react";
import { View } from "react-native";
import { Card } from "react-native-elements";
import QRCode from "react-native-qrcode-svg";
import * as Yup from "yup";
import Form from "../../components/Forms/Form";
import FormButton from "../../components/Forms/FormButton";
import { FormInput, FormRadio } from "../../components/Forms/FormField";
import { Button, Text } from "../../components/Themed";
import { useStores } from "../../models/root-store/root-store-context";
import { UserRoleSnapshot } from "../../models/UserRole";
import styles from "../../styles";

const validationSchema = Yup.object().shape({
  code: Yup.string().required().label("Code"),
  company: Yup.string().required().label("Company"),
});

export default function ValidatorScreen({
  navigation,
  route,
}: StackScreenProps<{}>) {
  const { authStore } = useStores();

  const logout = async () => {
    await authStore.signOut();
  };

  const [loading, setLoading] = React.useState(false);
  const [codeValid, setCodeValid] = React.useState<boolean>();
  const [ok, setOk] = React.useState<boolean>();
  const [code, setCode] = React.useState("");
  const [codeError, setCodeError] = React.useState("");
  const [user, setUser] = React.useState<any>();

  React.useEffect(() => {
    async function getUser() {
      const currentUser = await authStore.getCurrentUser();
      setUser(currentUser);
    }
    if (authStore !== undefined) {
      getUser();
    }
  }, [authStore, code]);

  const validateCode = async (data: any) => {
    setLoading(true);
    setCodeValid(undefined);
    const resp = await authStore.validateCode(data);
    if (!resp.ok) {
      setOk(resp.ok);
      setCodeValid(resp.ok);
      setCodeError(resp?.data?.detail);
      setLoading(false);
    }
  };

  const requestCode = async () => {
    setLoading(true);
    const resp = await authStore.requestCode();
    setOk(resp.ok);
    setCode(resp.data);
    setLoading(false);
  };

  const roles: any = [];

  user?.roles.forEach((role: UserRoleSnapshot) => {
    roles.push({
      value: `${role.company}:${role.companyName}:${role.uid}:${role.role}`,
      label: `${role.companyName}: ${role.role}`,
    });
  });

  return (
    <View style={styles.centeredContainer}>
      <Card containerStyle={styles.loginContainer}>
        {codeError ? (
          <Text style={[styles.centeredText, styles.errorText]}>
            {codeError}
          </Text>
        ) : (
          ok !== undefined &&
          !ok && (
            <Text style={[styles.centeredText, styles.errorText]}>
              Unable to set up your account. Please contact your manager
            </Text>
          )
        )}
        {user && (
          <>
            <Text h4 style={styles.centeredText}>
              Hi {user?.name}
            </Text>
            {user?.mfaEnabled ? (
              <Text style={styles.centeredText}>
                Please enter the code generated by your authenticator app.
              </Text>
            ) : (
              <View style={styles.marginV}>
                <Text style={[styles.centeredText, styles.tenPadding]}>
                  For extra security we need to enable Two Factor
                  Authentication. Please scan the QR code below and enter the
                  generated code.
                </Text>
                {(code === undefined || code?.length < 1) && (
                  <Button
                    raised
                    onPress={requestCode}
                    activeOpacity={0.8}
                    title="Start"
                    containerStyle={{ minWidth: 100 }}
                    loading={loading}
                    disabled={loading}
                  />
                )}
                {code?.length > 0 && (
                  <View style={[styles.tenPadding, styles.centered]}>
                    <QRCode value={code} size={200} />
                  </View>
                )}
              </View>
            )}
          </>
        )}
        {(code?.length > 0 || user?.mfaEnabled) && (
          <View style={styles.centered}>
            <Form
              initialValues={{
                code: "",
                company: roles.length === 1 ? roles[0].value : "",
              }}
              validationSchema={validationSchema}
              onSubmit={(values) => validateCode(values)}
            >
              <FormInput
                style={styles.centered}
                disabled={loading}
                placeholder="123456"
                name="code"
              />
              {user &&
                (user.superUser ? (
                  <FormInput
                    style={styles.centered}
                    disabled={loading}
                    placeholder="Company ID"
                    name="company"
                  />
                ) : (
                  <FormRadio
                    style={styles.centered}
                    placeholder="Select One"
                    name="company"
                    data={roles}
                  />
                ))}
              <FormButton
                raised
                activeOpacity={0.8}
                title="Submit"
                containerStyle={{ flex: 1 }}
                loading={loading}
                disabled={loading}
              />
              <Button
                raised
                type="outline"
                title="Logout"
                onPress={logout}
                containerStyle={{ flex: 1 }}
              />
            </Form>
          </View>
        )}
      </Card>
    </View>
  );
}
