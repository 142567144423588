import { StackScreenProps } from "@react-navigation/stack";
import React from "react";
import { Pressable, View } from "react-native";
import { Card } from "react-native-elements";
import { Text } from "../../components/Themed";
import { useStores } from "../../models/root-store/root-store-context";
import NavigationService from "../../navigation/NavigationService";
import styles from "../../styles";

export default function DashboardScreen({
  navigation,
  route,
}: StackScreenProps<{}>) {
  const { authStore } = useStores();

  const { user, companyName, role } = authStore;

  return (
    <View style={styles.container}>
      {companyName && (
        <View style={styles.centeredContainer}>
          <Text style={styles.dashHeading}>{companyName}</Text>
        </View>
      )}
      <View style={styles.flexContainer}>
        <Pressable onPress={NavigationService.goToPos} style={styles.dashTile}>
          <Card containerStyle={styles.dashCard}>
            <Text style={[styles.heading, styles.centeredText]}>POS</Text>
          </Card>
        </Pressable>
        <Pressable onPress={() => {}} style={styles.dashTile}>
          <Card containerStyle={styles.dashCard}>
            <Text style={[styles.heading, styles.centeredText]}>Products</Text>
          </Card>
        </Pressable>
      </View>
      <View style={styles.flexContainer}>
        <Pressable onPress={() => {}} style={styles.dashTile}>
          <Card containerStyle={styles.dashCard}>
            <Text style={[styles.heading, styles.centeredText]}>Customers</Text>
          </Card>
        </Pressable>
        <Pressable onPress={() => {}} style={styles.dashTile}>
          <Card containerStyle={styles.dashCard}>
            <Text style={[styles.heading, styles.centeredText]}>Settings</Text>
          </Card>
        </Pressable>
      </View>
    </View>
  );
}
