import { ApiResponse } from "apisauce";
import constants from "../../utils/constants";
import * as storage from "../../utils/storage";
import Api from "./api";
import { AppResponse, formatResponseMeta, userData } from "./helpers";

async function setAuth(response: ApiResponse<any>) {
  const { data } = response;
  if (data?.refresh && data?.access) {
    await storage.saveString(constants.TOKEN, data.access);
    await storage.saveString(constants.REFRESH_TOKEN, data.refresh);
  }
  return response.ok;
}

export default class UserAuthApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async CheckPhone(phone: string) {
    const response: ApiResponse<any> = await this.api.apisauce.post(
      `check-phone`,
      { phone }
    );
    return {
      ok: response.ok,
      data: response.data?.results || response.data,
      status: response.status,
      meta: formatResponseMeta(response),
    };
  }

  async ValidateQrCode(data: {}) {
    const response: ApiResponse<any> = await this.api.apisauce.post(
      `mfa`,
      data
    );
    return {
      ok: response.ok,
      data: response.data,
      status: response.status,
      meta: formatResponseMeta(response),
    };
  }

  async RequestQrCode() {
    const response: ApiResponse<any> = await this.api.apisauce.get(`mfa`);
    return {
      ok: response.ok,
      data: response.data?.code,
      status: response.status,
      meta: formatResponseMeta(response),
    };
  }

  async SignIn(username: string, password: string) {
    const response: ApiResponse<any> = await this.api.apisauce.post(
      `${constants.TOKEN}/`,
      { username, password }
    );
    return setAuth(response);
  }

  async RefreshToken() {
    const refresh = await storage.loadString(constants.REFRESH_TOKEN);
    if (!refresh) {
      return false;
    }
    const response: ApiResponse<any> = await this.api.apisauce.post(
      `${constants.REFRESH}/`,
      { refresh }
    );
    return setAuth(response);
  }

  async RevokeToken() {
    const refresh = await storage.loadString(constants.REFRESH_TOKEN);
    if (!refresh) {
      return;
    }
    await this.api.apisauce.post(`${constants.REVOKE}/`, { refresh });
  }

  async CurrentUser(): Promise<AppResponse> {
    const response: ApiResponse<any> = await this.api.apisauce.get(`me`);
    const resp = {
      data: response.data && userData(response.data),
      status: response.status,
      ok: response.ok,
      meta: formatResponseMeta(response),
    };
    return resp;
  }
}
