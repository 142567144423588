import { DrawerActions } from "@react-navigation/native";
import {
  createStackNavigator,
  StackScreenProps,
} from "@react-navigation/stack";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { useStores } from "../models/root-store/root-store-context";
import { AuthScreen, StagingScreen, ValidatorScreen } from "../screens";
import { AuthParamList } from "../types";
import constants from "../utils/constants";

const AuthStack = createStackNavigator<AuthParamList>();

const AuthNavigator = observer(
  ({ navigation, route }: StackScreenProps<{}>) => {
    const { ping, authStore } = useStores();
    const { problem } = ping;
    const { isAuthenticated } = authStore;

    React.useEffect(() => {
      async function Ping() {
        await ping.checkApi();
      }
      Ping();
    }, []);

    React.useEffect(() => {
      navigation.dispatch(DrawerActions.closeDrawer());
    }, [navigation]);

    return (
      <AuthStack.Navigator>
        {problem == constants.CONNECTION_ERROR ? (
          <AuthStack.Screen
            options={{ title: "POS" }}
            name="StagingScreen"
            component={StagingScreen}
          />
        ) : (
          <>
            {isAuthenticated ? (
              <AuthStack.Screen
                name="Validator"
                component={ValidatorScreen}
                options={{
                  title: "POS",
                  headerShown: false,
                }}
              />
            ) : (
              <AuthStack.Screen
                options={{ title: "POS", headerStyle: { height: 0 } }}
                name="AuthScreen"
                component={AuthScreen}
              />
            )}
          </>
        )}
      </AuthStack.Navigator>
    );
  }
);

export default AuthNavigator;
