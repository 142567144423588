let navigator;
let navStore;

function setTopLevelNavigator(navigatorRef) {
  navigator = navigatorRef;
}

function setNavigationStore(navigationStore) {
  navStore = navigationStore;
}

function currentRoute() {
  return navigator.getCurrentRoute();
}

function goHome() {
  navigator.navigate("Root", {
    screen: "Home",
    params: {
      screen: "DashScreen",
    },
  });
}

function goToPos() {
  navigator.navigate("Root", {
    screen: "Home",
    params: {
      screen: "HomeScreen",
    },
  });
}

export default {
  currentRoute,
  setNavigationStore,
  setTopLevelNavigator,
  goHome,
  goToPos,
};
