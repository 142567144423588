import { Instance, SnapshotOut, types } from "mobx-state-tree";
import ProductApi from "../services/api/product";
import withEnvironment from "./extensions/with-environment";

export const ProductModel = types
  .model("Product")
  .props({
    uid: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    code: types.maybeNull(types.string),
    category: types.maybeNull(types.string),
    categoryName: types.maybeNull(types.string),
    price: types.maybeNull(types.number),
    priceUid: types.maybeNull(types.string),
    minPrice: types.maybeNull(types.number),
    maxPrice: types.maybeNull(types.number),
    categoryParentName: types.maybeNull(types.string),
    isActive: types.maybeNull(types.boolean),
  })
  .extend(withEnvironment)
  .views((self) => ({
    search: async (query: string) => {
      if (query.length < 3) {
        return [];
      }
      const endpoint = new ProductApi(self.environment.api);
      const result = await endpoint.Search(query);

      return result.data;
    },
  }))
  .actions((self) => ({}));

type ProductType = Instance<typeof ProductModel>;
export interface Product extends ProductType {}
type ProductSnapshotType = SnapshotOut<typeof ProductModel>;
export interface ProductSnapshot extends ProductSnapshotType {}
export const createProductDefaultModel = () => types.optional(ProductModel, {});
