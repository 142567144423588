import { Dimensions, StyleSheet } from "react-native";
import * as Colors from "./constants/Colors";
import constants from "./utils/constants";

const { width } = Dimensions.get("window");
const MAX_WIDTH = 1280; // constant, standard width of galaxy tab s

const scaleFontSize = (fontSize) => {
  const ratio = fontSize / MAX_WIDTH; // get ratio based on your standard scale
  const newSize = Math.round(ratio * width);
  return newSize;
};

const styles = StyleSheet.create({
  /* PLOP_INJECT_CLASS */
  logo: {
    backgroundColor: null,
  },
  row: {
    flexDirection: "row",
    padding: 10,
    width: "100%",
  },
  gridColumn: {
    paddingHorizontal: 5,
    paddingVertical: 0,
  },
  rowHeader: {
    backgroundColor: Colors.grey300,
    borderTopColor: Colors.grey600,
    borderBottomColor: Colors.grey400,
    borderStyle: "solid",
    borderTopWidth: 1,
    borderBottomWidth: 1,
  },
  chipsContainer: {
    flexDirection: "row",
    backgroundColor: Colors.grey400,
  },
  chipText: {
    fontSize: scaleFontSize(12),
    color: Colors.black,
    lineHeight: 12,
  },
  inviteChipText: {
    fontSize: scaleFontSize(12),
    color: Colors.black,
  },
  inviteChip: {
    backgroundColor: Colors.grey400,
    alignSelf: "center",
  },
  chip: {
    backgroundColor: Colors.grey400,
    margin: 1,
    padding: 0,
  },
  tag: {
    margin: 1,
    padding: 2,
    fontSize: scaleFontSize(13),
    textTransform: "lowercase",
    textDecorationLine: "underline",
  },
  bold: {
    fontSize: scaleFontSize(14),
    fontWeight: "bold",
  },
  noSpace: {
    padding: 0,
    margin: 0,
  },
  container: {
    flex: 1,
    padding: constants.BASE_PADDING,
  },
  pickerContainer: {
    paddingTop: 10,
    marginHorizontal: 5,
  },
  fullWidth: {
    marginHorizontal: -constants.BASE_PADDING,
  },
  searchBarDiv: {
    margin: 0,
    borderWidth: 0,
  },
  searchBarInputContainer: {
    backgroundColor: Colors.grey300,
    borderWidth: 0,
  },
  searchBarContainer: {
    backgroundColor: undefined,
    borderWidth: 0,
    borderTopWidth: 0,
    borderBottomWidth: 0,
  },
  searchBarModal: {
    maxHeight: constants.SCREEN_HEIGHT * 0.85,
  },
  searchBar: {
    borderBottomWidth: 1,
    borderBottomColor: Colors.grey300,
  },
  searchBarInput: {
    paddingLeft: 5,
    fontSize: scaleFontSize(16),
    backgroundColor: "white",
  },
  searchResultTitle: {
    fontSize: scaleFontSize(16),
    fontWeight: "700",
  },
  searchItemTitle: {
    fontSize: scaleFontSize(15),
    fontWeight: "700",
    paddingLeft: 2,
  },
  searchItemList: {
    marginHorizontal: 1,
    paddingHorizontal: 2,
    fontSize: scaleFontSize(14),
    textDecorationLine: "underline",
  },
  noColor: {
    backgroundColor: null,
    alignSelf: "center",
  },
  centered: {
    alignItems: "center",
    marginLeft: "auto",
    marginRight: "auto",
  },
  centeredContainer: {
    flex: 1,
    margin: 10,
    alignItems: "center",
    justifyContent: "center",
  },
  dashCard: {
    top: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  dashTile: {
    flex: 1,
    margin: 40,
    minHeight: constants.SCREEN_HEIGHT * 0.25,
  },
  progressBarContainer: {
    flex: 1,
    marginHorizontal: -constants.BASE_PADDING,
  },
  title: {
    padding: 10,
    textAlign: "center",
    fontSize: scaleFontSize(22),
    fontWeight: "bold",
  },
  centeredText: {
    textAlign: "center",
  },
  capitalized: {
    textTransform: "uppercase",
  },
  tenPadding: {
    padding: 10,
  },
  snackbar: {
    flex: 1,
    justifyContent: "flex-end",
    position: "absolute",
    bottom: 0,
  },
  noPadding: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
  },
  noMargin: {
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0,
  },
  link: {
    fontSize: scaleFontSize(16),
    color: Colors.green500,
    textTransform: "none",
  },
  marginV: {
    marginVertical: 5,
  },
  marginA: {
    marginTop: "auto",
    marginBottom: "auto",
  },
  marginH: {
    marginHorizontal: 5,
  },
  button: {
    maxWidth: "100%",
  },
  buttonText: {
    fontSize: scaleFontSize(14),
    lineHeight: 14,
  },
  sideMenuProfileIcon: {
    alignSelf: "center",
    marginTop: 48,
    alignItems: "center",
    justifyContent: "center",
  },
  menuIconStyle: {
    marginHorizontal: 5,
    paddingHorizontal: 10,
  },
  avatarContainer: {
    alignItems: "center",
    backgroundColor: null,
  },
  formContainer: {
    borderRadius: 10,
    minWidth: "50%",
    padding: 15,
    marginVertical: 10,
  },
  main: {
    width: constants.SCREEN_WIDTH,
    justifyContent: "center",
    alignSelf: "center",
    maxHeight: 800,
  },
  mainDrawer: {
    justifyContent: "center",
  },
  loginContainer: {
    borderRadius: 10,
    padding: 15,
    marginVertical: 10,
    maxWidth: "50%",
    minWidth: "40%",
  },
  buttonContainer: {
    marginVertical: 10,
    paddingHorizontal: 5,
    width: "50%",
    alignSelf: "center",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    maxWidth: 150,
  },
  flexEvenlyContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  flexContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
  },
  dashTilesContainer: {
    maxWidth: constants.SCREEN_WIDTH,
    margin: 30,
    padding: 30,
    borderColor: Colors.grey300,
    borderStyle: "solid",
    borderWidth: 1,
  },
  homeContainer: {
    flex: 1,
    flexDirection: "row",
    margin: 5,
    padding: 0,
  },
  inputContainer: {
    flexDirection: "row",
    alignItems: "center",
    width: "90%",
  },
  defaultText: {
    fontSize: scaleFontSize(14),
  },
  inputText: {
    backgroundColor: null,
    borderWidth: 0,
    borderColor: "#eee",
    paddingLeft: 10,
  },
  discountInput: {
    borderWidth: 0,
    borderColor: "#eee",
    padding: 0,
    margin: 0,
    fontSize: scaleFontSize(12),
  },
  discountInputContainer: {
    backgroundColor: null,
    borderWidth: 0,
    borderColor: "#eee",
  },
  inputIcon: {
    marginLeft: 0,
    paddingLeft: 0,
  },
  name: {
    fontSize: scaleFontSize(18),
    fontWeight: "600",
    padding: 5,
  },
  info: {
    fontSize: scaleFontSize(18),
    marginTop: 10,
  },
  listHeading: {
    fontSize: scaleFontSize(18),
    textTransform: "uppercase",
    fontWeight: "bold",
    textAlign: "center",
    paddingVertical: 10,
  },
  dashHeading: {
    fontSize: scaleFontSize(18),
    textTransform: "uppercase",
    fontWeight: "bold",
    textAlign: "center",
  },
  heading: {
    fontSize: scaleFontSize(18),
    textTransform: "uppercase",
    fontWeight: "bold",
    padding: 10,
  },
  subheading: {
    fontSize: scaleFontSize(18),
    fontWeight: "bold",
    flexWrap: "nowrap",
  },
  total: {
    fontSize: scaleFontSize(14),
    padding: 0,
    fontWeight: "bold",
  },
  cardListTitle: {
    fontSize: scaleFontSize(16),
    fontWeight: "500",
  },
  connectionsListTitle: {
    fontSize: scaleFontSize(14),
    fontWeight: "500",
  },
  customerTitle: {
    fontSize: scaleFontSize(18),
    fontWeight: "bold",
  },
  listItem: {
    padding: 0,
    fontSize: scaleFontSize(12),
  },
  checkBoxLabel: {
    fontSize: scaleFontSize(12),
    padding: 0,
  },
  inputLabel: {
    fontSize: scaleFontSize(14),
    padding: 0,
    fontWeight: "bold",
  },
  checkbox: {
    alignSelf: "center",
  },
  checkboxContainer: {
    paddingVertical: 0,
    marginVertical: 0,
    alignItems: "center",
    justifyContent: "center",
  },
  radioContainer: {
    paddingVertical: 0,
    marginVertical: 0,
    flexDirection: "row",
    textAlignVertical: "center",
  },
  listCard: {
    marginVertical: 2,
  },
  small: {
    fontSize: scaleFontSize(10),
  },
  tabBar: {
    backgroundColor: "white",
  },
  fab: {
    position: "absolute",
    marginHorizontal: 16,
    right: 0,
    bottom: 0,
  },
  wrap: {
    flexWrap: "wrap",
    flexShrink: 1,
    alignItems: "flex-start",
  },
  noWrap: {
    flexWrap: "nowrap",
    flexDirection: "row",
    padding: 5,
  },
  plainIcon: {
    backgroundColor: null,
    borderColor: null,
    borderWidth: 0,
    margin: 2,
    alignSelf: "center",
  },
  icon: {
    margin: 2,
    alignSelf: "center",

    borderWidth: 0.2,
  },
  iconText: {
    fontSize: scaleFontSize(10),
  },
  jumbotron: {
    padding: 10,
    marginVertical: 10,
    backgroundColor: Colors.grey300,
    borderRadius: 5,
  },
  error: {
    color: Colors.red500,
  },
  errorText: {
    paddingLeft: constants.BASE_PADDING,
    color: Colors.red500,
  },
  deposit: {
    alignSelf: "flex-end",
    color: Colors.green500,
  },
  withdrawal: {
    alignSelf: "flex-end",
    color: Colors.red500,
  },
  appButton: {
    marginVertical: 4,
    marginHorizontal: 2,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 5,
    // backgroundColor: Colors.primary,
    paddingVertical: 5,
    paddingHorizontal: 15,
    borderColor: Colors.grey400,
    borderWidth: 0.5,
  },
  appButtonText: {
    // color: Colors.accent,
    fontSize: scaleFontSize(16),
    fontWeight: "600",
    textTransform: "uppercase",
  },
  strikeThrough: {
    textDecorationLine: "line-through",
    textDecorationStyle: "solid",
  },
  placeholder: {
    color: Colors.grey600,
    lineHeight: 36,
    fontSize: scaleFontSize(13),
    height: 48,
    margin: 10,
    paddingHorizontal: 20,
    paddingLeft: 10,
  },
  pickerText: {
    lineHeight: 36,
    fontSize: scaleFontSize(13),
    height: 48,
    margin: 0,
    padding: 0,
    // flex: 1,
  },
  divider: {
    backgroundColor: Colors.grey400,
    marginTop: 10,
  },
  f2: {
    flex: 2,
  },
  f1: {
    flex: 1,
  },
});

export default styles;
