import Environment from "../environment";

/**
 * Setup the environment that all the models will be sharing.
 *
 * The environment includes other functions that will be picked from some
 * of the models that get created later. This is how we loosly couple things
 * like events between models.
 */
export default async function createEnvironment() {
  const env = new Environment();
  await env.setup();
  return env;
}
