import { debounce } from "lodash";
import React from "react";
import { FlatList, StyleSheet, TouchableOpacity, View } from "react-native";
import { CheckBox, Icon, SearchBar } from "react-native-elements";
import { grey400, red100 } from "../../constants/Colors";
import { ProductSnapshot } from "../../models";
import { useSharedStores } from "../../models/shared-store/shared-store-context";
import styles from "../../styles";
import { Text } from "../Themed";

const localStyles = StyleSheet.create({
  resultRow: {
    padding: 5,
    flexDirection: "row",
    width: "100%",
  },
  disabledResultRow: {
    padding: 5,
    flexDirection: "row",
    width: "100%",
    backgroundColor: red100,
  },
  base: {
    padding: 0,
    marginVertical: 0,
    alignItems: "center",
  },
  results: {
    borderTopColor: grey400,
    borderTopWidth: 1,
  },
  left: {
    flex: 1,
    borderColor: grey400,
    borderWidth: 1,
    padding: 10,
    margin: 3,
  },
  right: {
    borderColor: grey400,
    borderWidth: 1,
    flex: 2,
    margin: 3,
    padding: 10,
  },
});

export default function Header() {
  const { product, cart } = useSharedStores();

  const [searchValue, setSearchValue] = React.useState("");
  const [checked, setChecked] = React.useState<string | null>(null);
  const [refreshing, setRefreshing] = React.useState(false);
  const [searchData, setSearchData] = React.useState<ProductSnapshot[]>([]);

  const handler = React.useCallback(
    debounce(async (val) => {
      setRefreshing(true);
      const data = await product.search(val);
      setSearchData(data);
      setRefreshing(false);
    }, 500),
    []
  );

  React.useEffect(() => {
    handler(searchValue);
  }, [searchValue]);

  const selectItem = (item: ProductSnapshot) => {
    setChecked(item.uid);
    cart.addToCart(item);
    setSearchValue("");
    setChecked(null);
  };

  const EmptyListMessage = () => {
    if (!refreshing) {
      if (searchValue.length >= 3 && searchData.length === 0) {
        return (
          <View style={[styles.row, localStyles.base]}>
            <Text>Nothing found. Please try again</Text>
          </View>
        );
      }
      if (searchValue !== "" && searchValue.length < 3) {
        return (
          <View style={[styles.row, localStyles.base]}>
            <Text>At least 3 characters required</Text>
          </View>
        );
      }
    }
    return <></>;
  };

  return (
    <>
      <View style={[styles.row, localStyles.base]}>
        <View style={localStyles.left}>
          <Text style={styles.customerTitle}>Customer</Text>
          <View style={styles.row}>
            <Text style={styles.bold}>Walk-In</Text>
            <Icon
              style={styles.icon}
              size={14}
              solid
              name="create-outline"
              type="ionicon"
            />
          </View>
        </View>
        <View style={localStyles.right}>
          <SearchBar
            lightTheme
            inputContainerStyle={styles.searchBarInputContainer}
            containerStyle={styles.searchBarContainer}
            inputStyle={styles.searchBarInput}
            style={styles.searchBar}
            placeholder="Search Product..."
            platform="default"
            onChangeText={setSearchValue}
            value={searchValue}
          />
        </View>
      </View>
      {searchData !== undefined && (
        <View style={[localStyles.results, styles.row]}>
          <FlatList
            data={searchData}
            ListEmptyComponent={EmptyListMessage}
            renderItem={({ item }) => (
              <TouchableOpacity
                style={
                  item.price
                    ? localStyles.resultRow
                    : localStyles.disabledResultRow
                }
                onPress={() => selectItem(item)}
                disabled={!item.price}
              >
                <CheckBox
                  style={styles.checkbox}
                  containerStyle={styles.checkboxContainer}
                  checkedIcon="dot-circle-o"
                  uncheckedIcon="circle-o"
                  checked={checked === item.uid}
                  onPress={() => selectItem(item)}
                />
                <View style={{ flex: 1 }}>
                  <Text>{item.code}</Text>
                </View>
                <View style={{ flex: 2 }}>
                  <Text>
                    {item.name}
                    {!item.price && (
                      <Text style={[styles.error, styles.small]}>
                        &nbsp;(Price not set)
                      </Text>
                    )}
                  </Text>
                </View>
                <View style={{ flex: 2 }}>
                  <Text>{item.categoryName}</Text>
                </View>
              </TouchableOpacity>
            )}
            keyExtractor={(item) => "" + item.uid}
          />
        </View>
      )}
    </>
  );
}
