import { Instance, SnapshotOut, types } from "mobx-state-tree";
import { AuthStore, AuthStoreModel } from "../AuthStore";
import { NavigationStore } from "../Navigation";
import { PingModel } from "../Ping";
/**
 * A RootStore model.
 */
// prettier-ignore
export const RootStoreModel = types.model("RootStore").props({
  authStore: types.optional(AuthStoreModel, {} as AuthStore),
  ping: types.optional(PingModel, {} as any),
  navigationStore: types.optional(NavigationStore, {} as any),
})

/**
 * The RootStore instance.
 */
export interface RootStore extends Instance<typeof RootStoreModel> {}

/**
 * The data of a RootStore.
 */
export interface RootStoreSnapshot extends SnapshotOut<typeof RootStoreModel> {}
