import { ApiResponse } from "apisauce";
import moment from "moment";
import { ProductSnapshot } from "../../models/Product";
import { UserSnapshot } from "../../models/User";
import { UserRoleSnapshot } from "../../models/UserRole";
import { GeneralApiProblem, getGeneralApiProblem } from "./api-problem";

export const capitalize = (text: string) =>
  text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();

export const response = (obj: any) => ({
  count: obj.count,
  next: obj.next,
  previous: obj.previous,
  results: obj.results,
});

export type ResponseMeta = {
  count: any;
  hasNext: any;
  hasPrevious: any;
  limit: any;
  problem: GeneralApiProblem | void;
};

export type AppResponse = {
  data: any;
  ok: any;
  meta: ResponseMeta;
  status: any;
};

export type SearchResponse = {
  data: any;
  count: any;
  status: any;
  problem: any;
};

export const formatResponseMeta = (resp: ApiResponse<any>) => {
  const respData: ResponseMeta = {
    count: null,
    hasNext: null,
    hasPrevious: null,
    limit: null,
    problem: getGeneralApiProblem(resp),
  };
  if (resp?.data) {
    respData.count = resp.data.count;
    respData.hasNext = typeof resp.data.next === "string";
    respData.hasPrevious = typeof resp.data.previous === "string";
  }
  return respData;
};

export const userRoleData = (obj: any): UserRoleSnapshot => ({
  uid: obj.uid,
  isActive: obj.is_active,
  updatedAt: moment(obj.updated_at).format("DD-MM-YYYY"),
  addedBy: obj.added_by,
  company: obj.company,
  companyName: obj.company_name,
  role: capitalize(obj.role),
});

export const userData = (obj: any): UserSnapshot => ({
  uid: obj.uid,
  lastLogin: obj.last_login,
  username: obj.username,
  name: obj.name,
  isActive: obj.is_active,
  dateJoined: obj.date_joined,
  phoneNumber: obj.phone_number,
  superUser: obj.is_superuser,
  email: obj.email,
  picture: obj.picture,
  picUrl: obj.pic_url,
  accountNumber: obj.account_number,
  mfaEnabled: obj.mfa_enabled,
  addedBy: obj.added_by,
  companyName: obj.company_name,
  companyUid: obj.company_uid,
  roleUid: obj.role_uid,
  role: obj.role,
  roles: obj.roles?.map(userRoleData),
});

export const productData = (obj: any): ProductSnapshot => ({
  uid: obj.uid,
  name: obj.name,
  category: obj.category.uid,
  categoryName: obj.category.name,
  categoryParentName: obj.category.parent_name,
  isActive: obj.is_active,
  code: obj.code,
  priceUid: obj.price.uid,
  price: +obj.price.price,
  minPrice: +obj.price.min_price,
  maxPrice: +obj.price.max_price,
});
