import { onSnapshot } from "mobx-state-tree";
import NavigationService from "../../navigation/NavigationService";
import constants from "../../utils/constants";
import * as storage from "../../utils/storage";
import createEnvironment from "../extensions/utils";
import { RootStore, RootStoreModel } from "./root-store";

/**
 * Setup the root state.
 */
export default async function setupRootStore() {
  let rootStore: RootStore;
  let data: any;

  // prepare the environment that will be associated with the RootStore.
  const env = await createEnvironment();
  try {
    // load data from storage
    data = (await storage.load(constants.ROOT_STATE_STORAGE_KEY)) || {};

    rootStore = RootStoreModel.create(data, env);
  } catch (e) {
    // if there's any problems loading, then let's at least fallback to an empty state
    // instead of crashing.
    rootStore = RootStoreModel.create({}, env);
  }
  NavigationService.setNavigationStore(rootStore.navigationStore);
  // track changes & save to storage
  onSnapshot(rootStore, (snapshot) => {
    storage.save(constants.ROOT_STATE_STORAGE_KEY, snapshot);
  });

  return rootStore;
}
