import React from "react";
import { View } from "react-native";
import { Button, Text } from "../../components/Themed";
import { useStores } from "../../models/root-store/root-store-context";
import styles from "../../styles";
import { delay } from "../../utils/delay";

export default function StagingScreen() {
  const [loading, setLoading] = React.useState(false);
  const [retryCount, setRetryCount] = React.useState(0);

  const { ping } = useStores();

  const retry = async () => {
    setLoading(true);
    await delay(5);
    await ping.checkApi();
    setLoading(false);
    setRetryCount(retryCount + 1);
  };

  return (
    <View style={styles.centeredContainer}>
      <Text h2>Unable to establish connection.</Text>
      <Text style={styles.small}>
        Offline mode is only available when you're logged in
      </Text>
      <Text style={styles.small}>Please check your internet settings.</Text>
      <View style={styles.formContainer}>
        <Button
          raised
          title={retryCount < 3 ? "Try Again" : "Restart app to try again"}
          containerStyle={{ flex: 1 }}
          onPress={retry}
          loading={loading}
          disabled={loading || retryCount >= 3}
        />
      </View>
    </View>
  );
}
