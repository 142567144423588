import { debounce } from "lodash";
import { observer } from "mobx-react-lite";
import React from "react";
import { View } from "react-native";
import { Input } from "react-native-elements";
import { useSharedStores } from "../../models/shared-store/shared-store-context";
import styles from "../../styles";
import { Text } from "../Themed";

const Summary = observer(() => {
  const { cart } = useSharedStores();
  const { count, subtotal, itemsDiscount, saleDiscount, total, status } = cart;

  const [discountVal, setDiscount] = React.useState(`${saleDiscount}`);
  const [error, setError] = React.useState("");

  const handler = React.useCallback(
    debounce(async (val) => {
      const valid = /[+-]?([0-9]*[.])?[0-9]+/.test(val);
      setError("");
      if (!valid) {
        setError("Please enter a valid number.");
        return;
      }
      cart.addCartDiscount(+val);
    }, 500),
    []
  );

  React.useEffect(() => {
    handler(discountVal);
  }, [discountVal]);

  return (
    <View>
      <View style={styles.row}>
        <Text style={styles.f1}>Total Items</Text>
        <Text style={styles.f1}>{count}</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.f1}>Subtotal</Text>
        <Text style={styles.f1}>{subtotal}</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.f1}>Items Discount (sh)</Text>
        <Text style={styles.f1}>{itemsDiscount}</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.f1}>Additional Discount (sh)</Text>
        <Input
          keyboardType="numeric"
          value={discountVal}
          inputStyle={styles.discountInput}
          onChangeText={setDiscount}
          errorStyle={{ color: "red", fontSize: 10 }}
          errorMessage={error}
          containerStyle={[styles.f1, { maxHeight: 48 }]}
          disabled={status == "processing"}
        />
      </View>
      <View style={styles.row}>
        <Text style={styles.f1}>Tax</Text>
        <Text style={styles.f1}>0</Text>
      </View>
      <View style={styles.row}>
        <Text style={[styles.total, styles.f1]}>Total</Text>
        <Text style={[styles.total, styles.f1]}>{total}</Text>
      </View>
    </View>
  );
});

export default Summary;
