import { ApisauceInstance, create } from "apisauce";
import constants from "../../utils/constants";
import * as storage from "../../utils/storage";
import { ApiConfig, DEFAULT_API_CONFIG } from "./api-config";

/**
 * Manages all requests to the API.
 */
export default class Api {
  /**
   * The underlying apisauce instance which performs the requests.
   */
  apisauce!: ApisauceInstance;

  /**
   * Configurable options.
   */
  config: ApiConfig;

  /**
   * Creates the api.
   *
   * @param config The configuration to use.
   */
  constructor(config: ApiConfig = DEFAULT_API_CONFIG) {
    this.config = config;
  }

  /**
   * Sets up the API.  This will be called during the bootup
   * sequence and will happen before the first React component
   * is mounted.
   *
   * Be as quick as possible in here.
   */
  setup() {
    // construct the apisauce instance
    this.apisauce = create({
      baseURL: this.config.url,
      timeout: this.config.timeout,
      headers: {
        Accept: "application/json",
      },
    });
    this.apisauce.addAsyncRequestTransform((request) => async () => {
      const authEndpoints = [
        constants.REFRESH,
        constants.REVOKE,
        constants.TOKEN,
      ];
      if (request.url) {
        const token = await storage.loadString(constants.TOKEN);
        const company_uid = await storage.loadString(constants.COMPANY_UID);
        if (company_uid) {
          const c_header = "company-uid";
          this.apisauce.setHeader(c_header, company_uid);
          request.headers[c_header] = company_uid;
        }
        const company_name = await storage.loadString(constants.COMPANY_NAME);
        if (company_name) {
          const c_header = "company-name";
          this.apisauce.setHeader(c_header, company_name);
          request.headers[c_header] = company_name;
        }
        const role_uid = await storage.loadString(constants.ROLE_UID);
        if (role_uid) {
          const r_header = "role-uid";
          this.apisauce.setHeader(r_header, role_uid);
          request.headers[r_header] = role_uid;
        }

        if (token && !authEndpoints.includes(request.url)) {
          this.apisauce.setHeader("Authorization", `Token ${token}`);
          request.headers["Authorization"] = `Token ${token}`;
        } else {
          this.apisauce.deleteHeader("Authorization");
        }
      }
    });
  }
}
