import { Instance, SnapshotOut, types } from "mobx-state-tree";
import { CartModel } from "..";
import { ProductModel } from "../Product";
import { UserModel } from "../User";

/**
 * A SharedStore model.
 */
// prettier-ignore
export const SharedStoreModel = types.model("SharedStore").props({
  users: types.optional(UserModel, {} as any),
  product: types.optional(ProductModel, {} as any),
  cart: types.optional(CartModel, {} as any),
})

/**
 * The SharedStore instance.
 */
export interface SharedStore extends Instance<typeof SharedStoreModel> {}

/**
 * The data of a SharedStore.
 */
export interface SharedStoreSnapshot
  extends SnapshotOut<typeof SharedStoreModel> {}
