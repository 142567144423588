import { ApiResponse } from "apisauce";
import { CartSnapshot } from "../../models";
import constants from "../../utils/constants";
import * as storage from "../../utils/storage";
import Api from "./api";
import { AppResponse, formatResponseMeta, productData } from "./helpers";

export default class ProductApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async Search(query: string): Promise<any> {
    const response: ApiResponse<any> = await this.api.apisauce.get(`search`, {
      query,
    });
    var data = [];
    if (response.ok) {
      data = response.data.results;
    }
    const resp = {
      data: data.map(productData),
      status: response.status,
      ok: response.ok,
      meta: formatResponseMeta(response),
    };
    return resp;
  }

  async getOpenCartItems(): Promise<AppResponse> {
    const response: ApiResponse<any> = await this.api.apisauce.get(`sale`);
    var data = [];
    if (response.ok) {
      data = response.data;
    }
    const resp = {
      data: data,
      status: response.status,
      ok: response.ok,
      meta: formatResponseMeta(response),
    };
    return resp;
  }

  async recordSale(cart: CartSnapshot): Promise<AppResponse> {
    const role_uid = await storage.loadString(constants.ROLE_UID);

    const response: ApiResponse<any> = await this.api.apisauce.post(`sale`, {
      role: role_uid,
      cart,
    });

    const resp = {
      data: [],
      status: response.status,
      ok: response.ok,
      meta: formatResponseMeta(response),
    };
    return resp;
  }

  async recordTransaction(data: any): Promise<AppResponse> {
    const response: ApiResponse<any> = await this.api.apisauce.post(
      `transactions`,
      data
    );

    const resp = {
      data: [],
      status: response.status,
      ok: response.ok,
      meta: formatResponseMeta(response),
    };
    return resp;
  }
}
