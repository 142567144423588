import { Instance, SnapshotOut, types } from "mobx-state-tree";
import "react-native-get-random-values";
import { v4 as uuidv4 } from "uuid";
import withEnvironment from "./extensions/with-environment";

export const CartItemModel = types
  .model("CartItem")
  .props({
    productUid: types.identifier,
    uid: types.optional(types.string, uuidv4()),
    name: types.string,
    priceUid: types.string,
    quantity: types.optional(types.number, 1),
    amount: types.optional(types.number, 0),
    price: types.number,
    discount: types.number,
    discountAmount: types.optional(types.number, 0),
    discountPercent: types.optional(types.string, "0"),
    discountType: types.union(
      types.literal("amount"),
      types.literal("percent")
    ),
  })
  .extend(withEnvironment)
  .views((self) => ({}))
  .actions((self) => ({}));

type CartItemType = Instance<typeof CartItemModel>;
export interface CartItem extends CartItemType {}
type CartItemSnapshotType = SnapshotOut<typeof CartItemModel>;
export interface CartItemSnapshot extends CartItemSnapshotType {}
