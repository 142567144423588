import { Instance, SnapshotOut, types } from "mobx-state-tree";
import withEnvironment from "./extensions/with-environment";
import { UserRoleModel } from "./UserRole";

export const UserModel = types
  .model("User")
  .props({
    uid: types.maybeNull(types.string),
    lastLogin: types.maybeNull(types.string),
    username: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    isActive: types.maybeNull(types.boolean),
    dateJoined: types.maybeNull(types.string),
    phoneNumber: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    picture: types.maybeNull(types.string),
    picUrl: types.maybeNull(types.string),
    accountNumber: types.maybeNull(types.string),
    superUser: types.optional(types.boolean, false),
    mfaEnabled: types.optional(types.boolean, false),
    addedBy: types.maybeNull(types.string),
    companyName: types.maybeNull(types.string),
    companyUid: types.maybeNull(types.string),
    role: types.maybeNull(types.string),
    roleUid: types.maybeNull(types.string),
    roles: types.array(UserRoleModel),
  })
  .extend(withEnvironment)
  .views((self) => ({}))
  .actions((self) => ({}));

type UserType = Instance<typeof UserModel>;
export interface User extends UserType {}
type UserSnapshotType = SnapshotOut<typeof UserModel>;
export interface UserSnapshot extends UserSnapshotType {}
export const createUserDefaultModel = () =>
  types.optional(UserModel, { uid: "" });
