import { types } from "mobx-state-tree";

export const RouteParamsData = types.model("ParamsData", {
  uid: types.maybeNull(types.string),
});

export const RouteParams = types.model("RouteParams", {
  routeKey: types.identifier,
  screen: types.string,
  params: types.maybeNull(RouteParamsData),
});

export const NavigationStore = types
  .model("NavigationStore", {
    paramsMap: types.map(RouteParams),
  })
  .views((self) => ({
    getParamsForCurrentRoute(key: string) {
      return self.paramsMap.get(key);
    },
  }))
  .actions((self) => ({
    setParamsForRoute(routeParams: any) {
      self.paramsMap.put(routeParams);
    },
  }));
