import { observer } from "mobx-react-lite";
import React from "react";
import { StyleSheet, View } from "react-native";
import { ButtonGroup, Icon } from "react-native-elements";
import * as Colors from "../../constants/Colors";
import { Discount } from "../../modals";
import { CartItemSnapshot } from "../../models/CartItem";
import { useSharedStores } from "../../models/shared-store/shared-store-context";
import styles from "../../styles";
import constants from "../../utils/constants";
import { Text } from "../Themed";

const localStyles = StyleSheet.create({
  cartRow: {
    borderBottomColor: Colors.grey300,
    borderBottomWidth: 1,
    flexDirection: "row",
  },
  cartIcon: {
    padding: 0,
    borderRadius: 0,
  },
  disabledCartIcon: {
    backgroundColor: undefined,
  },
  cellText: {
    flex: 1,
    padding: 0,
  },
  flex: {
    flex: 1,
    flexDirection: "row",
    height: "100%",
  },
  buttonContainer: {
    marginTop: 0,
    padding: 0,
    height: "100%",
  },
  button: {
    padding: 0,
    margin: 0,
  },
  iconsContainer: {
    flex: 1,
    padding: 0,
    margin: 0,
    marginRight: 5,
    flexDirection: "row",
    borderRadius: 0,
  },
  buttonText: {
    lineHeight: 14,
  },
});

const CartItem = observer((props: any) => {
  const { item } = props;
  const { cart } = useSharedStores();
  const [selectedDiscount, setSelectedDiscount] = React.useState(0);
  const [selectedDiscountType, setSelectedDiscountType] = React.useState("");
  const [showModal, setShowModal] = React.useState(false);
  const {
    name,
    quantity,
    productUid,
    price,
    discountAmount,
    discountPercent,
    discountType,
  }: CartItemSnapshot = item;

  const { status } = cart;

  const discountTypes = [constants.AMOUNT, constants.PERCENT];
  const discountButtonLabels = ["sh", `%`];

  const selectDiscount = (val: number) => {
    setSelectedDiscountType(discountTypes[val]);
    setShowModal(true);
  };

  const hideModal = () => {
    setShowModal(false);
    cart.compute();
  };

  React.useEffect(() => {
    discountType === constants.AMOUNT
      ? setSelectedDiscount(0)
      : setSelectedDiscount(1);
  }, [discountType]);

  return (
    <View style={[localStyles.cartRow, styles.row]}>
      {showModal && (
        <Discount
          data={{ item: item, discountType: selectedDiscountType }}
          isVisible={showModal}
          onDismiss={hideModal}
        />
      )}
      <View style={[styles.gridColumn, { flex: 2 }]}>
        <Text>{name}</Text>
      </View>
      <View style={[styles.gridColumn, { flex: 1 }]}>
        <View style={{ flexDirection: "row" }}>
          <Text style={localStyles.cellText}>{quantity}</Text>
          <View style={localStyles.iconsContainer}>
            <Icon
              borderRadius={0}
              iconStyle={localStyles.cartIcon}
              onPress={() => cart.addItem(productUid)}
              size={22}
              name="add-circle"
              type="ionicon"
              disabled={status == "processing"}
              disabledStyle={localStyles.disabledCartIcon}
              color={status == "processing" ? Colors.grey500 : Colors.black}
            />
            <Icon
              borderRadius={0}
              iconStyle={localStyles.cartIcon}
              onPress={() => cart.removeItem(productUid)}
              size={22}
              color={
                quantity < 2 || status == "processing"
                  ? Colors.grey500
                  : Colors.black
              }
              disabled={quantity < 2 || status == "processing"}
              disabledStyle={localStyles.disabledCartIcon}
              name="remove-circle"
              type="ionicon"
            />
          </View>
        </View>
      </View>
      <View style={[styles.gridColumn, { flex: 1 }]}>
        <Text>{price}</Text>
      </View>
      <View style={[styles.gridColumn, { flex: 1 }]}>
        <View style={[localStyles.flex]}>
          <View style={localStyles.cellText}>
            <Text>{discountAmount}</Text>
            <Text style={styles.small}>({discountPercent}%)</Text>
          </View>
          <ButtonGroup
            buttons={discountButtonLabels}
            selectedIndex={selectedDiscount}
            onPress={(value) => {
              selectDiscount(value);
            }}
            containerStyle={[
              localStyles.flex,
              styles.noMargin,
              { marginRight: 5 },
            ]}
            buttonContainerStyle={localStyles.buttonContainer}
            buttonStyle={styles.button}
            textStyle={styles.buttonText}
            disabled={status == "processing"}
          />
        </View>
      </View>
      <View
        style={[styles.gridColumn, { flex: 1, flexDirection: "row-reverse" }]}
      >
        <Text>{price * quantity - discountAmount}</Text>
      </View>
    </View>
  );
});

export default CartItem;
