import { observer } from "mobx-react-lite";
import React from "react";
import { StyleSheet, View } from "react-native";
import { useSharedStores } from "../../models/shared-store/shared-store-context";
import styles from "../../styles";
import { Text } from "../Themed";
import CartItem from "./CartItem";

const localStyles = StyleSheet.create({
  base: {
    marginTop: 10,
  },
});
const Cart = observer(() => {
  const { cart } = useSharedStores();
  const { items, status } = cart;

  return (
    <View style={[localStyles.base, { opacity: status == "open" ? 1 : 0.5 }]}>
      <View style={[styles.row, styles.rowHeader]}>
        <View style={[styles.gridColumn, { flex: 2 }]}>
          <Text style={styles.total}>Item</Text>
        </View>
        <View style={[styles.gridColumn, { flex: 1 }]}>
          <Text style={styles.total}>Quantity</Text>
        </View>
        <View style={[styles.gridColumn, { flex: 1 }]}>
          <Text style={styles.total}>Price</Text>
        </View>
        <View style={[styles.gridColumn, { flex: 1 }]}>
          <Text style={styles.total}>Discount</Text>
        </View>
        <View
          style={[styles.gridColumn, { flex: 1, flexDirection: "row-reverse" }]}
        >
          <Text style={styles.total}>Total</Text>
        </View>
      </View>

      {Object.entries(items.toJSON()).map(([key, value]) => {
        return <CartItem key={key} item={value} />;
      })}
    </View>
  );
});

export default Cart;
