import { Picker } from "@react-native-picker/picker";
import { useFormikContext } from "formik";
import React from "react";
import { Text, View } from "react-native";
import { CheckBox, Divider } from "react-native-elements";
import styles from "../../styles";
import { TextInput } from "../Themed";
import FormErrorMessage from "./FormErrorMessage";

export function FormInput({ name, value, ...props }: any) {
  const {
    setFieldTouched,
    setFieldValue,
    isSubmitting,
    values,
    errors,
    touched,
  } = useFormikContext();

  React.useEffect(() => {
    if (value !== undefined) {
      setFieldValue(name, value);
    }
  }, [setFieldValue, value, name]);

  const {
    dependency,
    dependencyValue,
    disabled,
    label,
    onChangeText,
    ...otherProps
  } = props;

  const textChanged = (val: string) => {
    setFieldValue(name, val);
    if (onChangeText !== undefined) {
      onChangeText(val);
    }
  };

  if (
    dependency &&
    values[dependency].toLowerCase() !== dependencyValue.toLowerCase()
  ) {
    return <></>;
  }

  return (
    <View style={styles.inputContainer}>
      {label && (
        <View style={{ marginVertical: "auto" }}>
          <Text style={styles.inputLabel}>{label}: </Text>
        </View>
      )}
      <View style={styles.container}>
        <TextInput
          value={values[name]}
          onChangeText={textChanged}
          onBlur={() => setFieldTouched(name)}
          disabled={disabled || isSubmitting}
          {...otherProps}
        />
        <FormErrorMessage error={errors[name]} visible={touched[name]} />
      </View>
    </View>
  );
}

export function FormikSelect(props: any) {
  const { setFieldValue, values, errors, touched, setFieldTouched } =
    useFormikContext();

  const {
    name,
    placeholder,
    data,
    dependency,
    dependencyValue,
    ...otherProps
  } = props;

  if (
    dependency &&
    values[dependency].toLowerCase() !== dependencyValue.toLowerCase()
  ) {
    return <></>;
  }

  return (
    <>
      <Picker
        selectedValue={values[name]}
        onValueChange={(itemValue, itemIndex) => setFieldValue(name, itemValue)}
        onTouchStart={() => setFieldTouched(name)}
        {...otherProps}
      >
        <Picker.Item
          // style={styles.placeholder}
          label={placeholder}
          value=""
          key="ab-default"
        />
        {data.map((item: any) => (
          <Picker.Item
            // style={styles.pickerText}
            label={item.label}
            value={item.value}
            key={item.key || item.value}
          />
        ))}
      </Picker>
      <Divider style={styles.divider} />
      <FormErrorMessage error={errors[name]} visible={touched[name]} />
    </>
  );
}

export function FormRadio(props: any) {
  const { setFieldValue, values, errors, touched } = useFormikContext();

  const { name, data, dependency, dependencyValue, label } = props;

  if (
    dependency &&
    values[dependency].toLowerCase() !== dependencyValue.toLowerCase()
  ) {
    return <></>;
  }

  if (data && data.length === 1 && values[name] !== data[0].value) {
    setFieldValue(name, data[0].value);
  }

  return (
    <View style={styles.flexContainer}>
      {label && (
        <View style={{ marginVertical: "auto" }}>
          <Text style={styles.inputLabel}>{label}: </Text>
        </View>
      )}
      <View style={styles.container}>
        <View style={styles.radioContainer}>
          {data.map((item: any) => (
            <CheckBox
              center
              title={item.label}
              checkedIcon="dot-circle-o"
              uncheckedIcon="circle-o"
              checked={values[name] === item.value}
              onPress={() => setFieldValue(name, item.value)}
              key={item.key || item.value}
            />
          ))}
        </View>
        <FormErrorMessage error={errors[name]} visible={touched[name]} />
      </View>
    </View>
  );
}
