import { StackScreenProps } from "@react-navigation/stack";
import { capitalize } from "lodash";
import { observer } from "mobx-react-lite";
import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { Card, Divider } from "react-native-elements";
import { v4 as uuidv4 } from "uuid";
import Cart from "../../components/Pos/Cart";
import Header from "../../components/Pos/Header";
import Payment from "../../components/Pos/Payment";
import Summary from "../../components/Pos/Summary";
import { Button } from "../../components/Themed";
import { useStores } from "../../models/root-store/root-store-context";
import { useSharedStores } from "../../models/shared-store/shared-store-context";
import styles from "../../styles";

const homeStyles = StyleSheet.create({
  base: {
    margin: 5,
    padding: 0,
  },
  user: {
    width: "100%",
  },
});

const HomeScreen = observer(({ navigation, route }: StackScreenProps<{}>) => {
  const { authStore } = useStores();
  const { cart } = useSharedStores();

  const { user, companyName } = authStore;
  const { count, uid, status } = cart;

  const initCart = () => {
    cart.setUid(uuidv4());
  };

  const setProcessing = () => {
    cart.setProcessing();
  };

  return (
    <View style={styles.homeContainer}>
      {uid === "" ? (
        <Card containerStyle={[styles.centeredContainer, styles.f2]}>
          <View style={styles.formContainer}>
            <Button
              raised
              activeOpacity={0.8}
              title="New Sale"
              containerStyle={{ flex: 1 }}
              onPress={initCart}
            />
          </View>
        </Card>
      ) : (
        <Card containerStyle={[homeStyles.base, styles.f2]}>
          {status == "processing" ? <Payment /> : <Header />}
          <Cart />
        </Card>
      )}
      <Card containerStyle={[homeStyles.base, styles.f1]}>
        <View style={[styles.centered]}>
          <Text style={styles.heading}>{companyName}</Text>
          <Text>Logged in as: {user?.name}</Text>
        </View>
        <Divider style={styles.divider} />
        <View style={styles.centered}>
          <Text style={[styles.tenPadding, styles.subheading]}>Summary</Text>
          <Text>({capitalize(status)})</Text>
        </View>
        <Summary />
        <Divider style={styles.divider} />
        <View>
          <Button
            raised
            activeOpacity={0.8}
            title="Next"
            containerStyle={{ flex: 1 }}
            disabled={count < 1 || status == "processing"}
            onPress={setProcessing}
          />
        </View>
      </Card>
    </View>
  );
});

export default HomeScreen;
